import { defineStore } from "pinia";

export const useSideNavStore = defineStore("sideNavStore", () => {
  const visible = ref(false);
  const expanded = ref(true);

  function toggle() {
    this.visible = !this.visible;
  }

  function hide() {
    this.visible = false;
  }

  function toggleExpanded() {
    this.expanded = !this.expanded;
  }

  return { visible, expanded, toggle, hide, toggleExpanded };
});
